import React, { useReducer } from 'react';
import { graphql } from 'gatsby';
import { PortfolioList, Seo } from '../components';
import styled from 'styled-components';

const StyledPortfolio = styled.div`
  /* was 180deg */
  background: linear-gradient(320deg, #0d1e39 27%, #132e60 100%),
    -webkit-linear-gradient(320deg, #0d1e39 27%, #132e60 100%);
  height: 100%;
`;

const PortfolioContainer = styled.div`
  padding-top: 135px;
  .heading {
    margin-block: min(6rem, 8%) min(3rem, 15vw);
    text-align: center;
    font-size: 2rem;
    text-transform: uppercase;
    opacity: 0.9;
    color: var(--secondary);
    h1 {
      /* 280 - 425 27-38px */
      font-size: clamp(1.69rem, calc(0.36rem + 7.59vw), 2.38rem);
      /* padding left needs to match letter-spacing to handle extra spacing at the end of last word  */
      padding-left: clamp(12px, 4vw, 17px);
      letter-spacing: clamp(12px, 4vw, 17px);
      line-height: 1.866;
      text-shadow: 0px 10px 10px rgb(0 0 0 / 80%);
    }
  }

  .filter-list {
    width: 90%;
    margin-inline: auto;
    ul {
      list-style: none;
      padding: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      gap: 1rem;
      flex-wrap: wrap;
      .list-item {
        /* display: inline-block; */
        /* padding: 0 16px; */
        .list-btn {
          text-decoration: none;
          border: none;
          background: none;
          /* color: var(--deepBlue); */
          opacity: 0.9;
          letter-spacing: 2.3px;
          font-size: 15px;
          color: var(--lightBlue);
          font-family: Poppins, Montserrat, sans-serif;
          text-transform: uppercase;
          transition: color 0.4s var(--colorEase);
          &:hover {
            cursor: pointer;
            color: var(--white);
          }
          &.active {
            color: var(--secondary);
          }
        }
      }
    }
  }

  @media screen and (min-width: 450px) {
    .heading {
      h1 {
        /* 450-1134 38-60px */
        font-size: clamp(2.38rem, calc(1.47rem + 3.22vw), 3.75rem);
      }
    }
  }
  @media screen and (min-width: 575px) {
    .heading {
      width: 82%;
      margin-inline: auto;
    }
  }

  @media screen and (max-width: 716px) {
    .filter-list ul {
      margin-bottom: 0.3em;
    }
  }
`;

const ShowcaseContainer = styled.div`
  /* display: flex;
  gap: 2rem;
  flex-wrap: wrap; */
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  padding: 4.8rem 0rem;
  margin: 0 auto;
  grid-gap: 2rem 0rem;
  width: min(1350px, 90%);

  @media screen and (max-width: 716px) {
    > div {
      width: clamp(320px, 75vw, 390px);
    }
  }

  @media screen and (max-width: 1133px) {
    width: min(960px, 96%);
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2rem 1.5rem;
    padding-inline: 0.75rem;
  }

  @media screen and (min-width: 1134px) {
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    /* selection every direct child div element */
    > div {
      width: clamp(320px, 28vw, 400px);
    }
  }

  @media screen and (min-width: 1234px) {
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    /* selection every direct child div element */
  }

  /* show the zone (brooklyn) */
  @media screen and (min-width: 1350px),
    screen and (min-width: 500px) and (max-width: 716px) {
    .item-2-content-container {
      .desc-subheading {
        .card-subheading-divider {
          margin-inline: min(1.15vw, 0.5rem);
        }
        .zone {
          display: inline;
        }
      }
    }
  }
`;

const filterLIst = [
  { name: 'All', type: 'all' },
  { name: 'Restoration', type: 'filtered' },
  { name: 'Development', type: 'filtered' },
  { name: 'Commercial', type: 'filtered' }
];

const Portfolio = ({ data: { properties } }) => {
  const [propertiesList, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'all':
          return Object.assign({}, properties, { activeName: 'All' });
        case 'filtered':
          return {
            activeName: action.name,
            nodes: properties.nodes.filter(
              property => property.type === action.name
            )
          };
        default:
          return state;
      }
    },
    { ...properties, activeName: 'All' }
  );

  return (
    <StyledPortfolio>
      <Seo title="Portfolio" />
      <PortfolioContainer>
        <div className="heading">
          <h1>Our Portfolio</h1>
        </div>
        <div className="filter-list">
          <ul>
            {filterLIst.map(({ name, type }, i) => (
              <li key={name + i} className="list-item">
                <button
                  className={[
                    'list-btn',
                    propertiesList.activeName === name ? 'active' : ''
                  ].join(' ')}
                  onClick={() => dispatch({ name, type })}
                >
                  {name}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <ShowcaseContainer>
          <PortfolioList
            properties={propertiesList}
            width={'clamp(320px, 38vw, 380px)'}
            marginBottom="2rem"
          />
        </ShowcaseContainer>
      </PortfolioContainer>
    </StyledPortfolio>
  );
};

export const query = graphql`
  {
    properties: allContentfulProperty(
      sort: { order: DESC, fields: acquisitionDate }
    ) {
      nodes {
        id
        address
        bathrooms
        bedrooms
        city
        price
        squareFt
        status
        icon
        thumbnail {
          gatsbyImageData(placeholder: BLURRED)
          description
        }
        type
        slug
        zone
      }
    }
  }
`;

export default Portfolio;
